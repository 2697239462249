import React from "react";
import shortid from  "shortid";

const BrainingCampSection2 = (props) => {
    return(
        <section className="section-2">
            <div className="container">
                <div className="title-block" >
                    <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-18">
                            <h1 className="h1 section-title">{props.mainTitle}</h1>
                        </div>
                        <div className="col-lg-3"></div>
                    </div>
                </div>
                <div className="image-block">
                    <div className="image-items">
                        {props.imageslist?.map((object, i) => {
                            return (
                                <div className="item" key={shortid.generate()} >
                                    <img
                                        src={object.image1x?.sourceUrl}
                                        srcSet={object.image2x?.sourceUrl + " 2x, " + object.image1x?.sourceUrl + " 1x"}
                                        width={object.image1x?.width}
                                        alt={object.image1x?.altText}
                                    />
                                </div>     
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BrainingCampSection2;